<template>
  <b-badge
    :variant="valiant"
  >
    {{ title }}
  </b-badge>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import { kycStatusColors } from '@/views/apps/kyc/config/kycTypes'

export default {
  name: 'KycStatus',
  components: { BBadge },
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    title() {
      return this.status.toLowerCase().replace(/^\w/, char => char.toUpperCase()).replace(/_/g, ' ')
    },
    valiant() {
      return kycStatusColors[this.status]
    },
  },
  // data() {
  //   return {}
  // },
}
</script>
