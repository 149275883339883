<template>
  <div>
    <b-modal
      v-model="isModalShow"
      centered
      ok-title="Edit"
      size="lg"
      title="Edit user information"
      @ok="onEdit"
    >
      <validation-observer
        ref="validator"
      >
        <b-form>

          <b-row align-v="center">
            <template v-for="(input, key) in form ">
              <b-col
                v-if="key !== 'id' && key !== 'requestId'"
                :key="key"
                md="6"
                xl="6"
              >

                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    :name="key"
                    rules="required"
                  >

                    <label>{{ convertCamelCaseToCapitalizedSpaces(key) }}</label>
                    <b-form-datepicker
                      v-if="key.toLowerCase().includes('date')"
                      v-model="form[key]"
                      :date-format-options="{ year: 'numeric', month: 'numeric',day: 'numeric', }"
                      :state="!form[key] ? false : null"
                    />

                    <b-form-select
                      v-else-if="['citizenship', 'residentCountry'].includes(key)"
                      v-model="form[key]"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="countriesName"
                      :state="!form[key] ? false : null"
                      label="name"
                    />
                    <b-form-input
                      v-else
                      v-model="form[key]"
                      :state="!form[key] ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <KycCheck
      :description="description"
      :lists="[{ list, type: 'default' }]"
      :title="title"
      link="applicant-info"
    >
      <template
        v-if="isShowEditButton"
        #button
      >
        <div class="w-100 d-flex flex-row justify-content-md-end mb-2">
          <b-button
            variant="outline-primary"
            @click="showModal"
          >
            Edit
          </b-button>
        </div>

      </template>
    </KycCheck>
  </div>
</template>
<script>
import {
  BButton, BCol, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormSelect, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import KycCheck from '@/views/apps/kyc/components/check.vue'
import validationError from '@/mixins/validationError'

import waitRequest from '@/mixins/waitRequest'

export default {
  name: 'ApplicantInformation',
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    ToastificationContent,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    KycCheck,
    BButton,
    BRow,
    BCol,
    BFormInput,
  },
  mixins: [waitRequest, validationError],
  props: {
    mainData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      title: 'Applicant Information',
      description: 'Basic info, like name, phone, country etc.',
      isModalShow: false,
      form: {
        id: '',
        firstName: '',
        lastName: '',
        documentNumber: '',
        dateOfBirth: '',
        issuingDate: '',
        expiryDate: '',
        residentCountry: '',
        address: '',
        city: '',
        postCode: '',
        citizenship: '',
        requestId: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
      countries: 'countries/countries',
    }),
    isShowEditButton() {
      if (!this.listOwnerPermissions.includes('kyc-review')) return false
      if (this.mainData?.responsible?.id !== this.$store.getters['auth/admin'].id) return false
      return this.mainData?.checks?.id?.[0]?.status === 'verified'
    },
    countriesName() {
      return this.countries.map(country => country.name)
    },
    list() {
      const target = this.mainData.applicantInfo
      return Object.keys(target).map(key => ({
        title: this.convertCamelCaseToCapitalizedSpaces(key),
        value: target[key],
        type: 'default',
      }))
    },
  },
  watch: {
    mainData: {
      handler() {
        this.setForm()
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchCountries()
  },
  methods: {
    setForm() {
      Object.keys(this.mainData.applicantInfo).forEach(key => {
        if (this.form[key] !== undefined) this.form[key] = this.mainData.applicantInfo[key]
      })
      this.form.requestId = +this.$route.params.id
    },
    showModal() {
      this.setForm()
      this.isModalShow = true
      setTimeout(() => {
        this.registerValidator(this.$refs.validator)
      })
    },
    ...mapActions({
      setApplicantInformation: 'kyc/setApplicantInformation',
      fetchCountries: 'countries/fetchCountries',
    }),

    update() {
      this.waitRequest(() => this.setApplicantInformation(this.form)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Applicant Information is updated',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
          this.$emit('onChange')
          this.isModalShow = false
        })
        .catch(err => {
          this.checkErrors(err)
        }))
    },

    convertCamelCaseToCapitalizedSpaces(str) {
      return str.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase())
    },
    async onEdit(event) {
      this.registerValidator(this.$refs.validator)
      event.preventDefault()
      const isValid = await this.checkIsValidForm()
      if (this.requestInProgress || !isValid) return
      await this.update()
    },
  },
}
</script>
