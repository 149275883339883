<template>
  <b-row
    align-v="start"
    class="mb-3"
  >
    <b-col
      md="12"
      xl="9"
    >
      <h4 class="mb-2">Customer
        <template
          v-if="customer.telegramUsername"
        >
          /
          <span class="primary-color">
            {{ customer.telegramUsername }}
          </span>
        </template>
        <template v-if="customer.telegramId">
          / <span class="primary-color"> {{ customer.telegramId }}</span>
        </template>
      </h4>

      <b-row>
        <template v-for="item in infoRow">
          <b-col
            v-if="item.value"
            :key="item.label"
            mb="6"
            xl="3"
          >
            <div class="d-flex flex-row align-items-center text-nowrap">
              {{ item.label }}&nbsp;
              <KycStatus
                v-if="item.type === 'status'"
                :status="item.value"
              />
              <KycLevel
                v-else-if="item.type === 'level'"
                :level="item.value"
              />
              <b
                v-else
                class="text-nowrap"
              >{{ item.value }}</b>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-col>
    <b-col
      v-if="isShowViewButton"
      class="justify-content-xl-end d-flex mt-1 mt-xl-0"
      md="12"
      xl="3"
    >
      <b-button
        :to="`/kyc/${mainData.id}/detail`"
        class="text-left"
        variant="outline-primary"
      >
        View full profile
      </b-button>
    </b-col>
    <b-col
      v-if="isShowDownloadButton"
      class="justify-content-xl-end d-flex mt-1 mt-xl-0"
      md="12"
      xl="3"
    >
      <b-button
        :to="`/kyc/${mainData.id}/detail`"
        class="text-left"
        variant="outline-primary"
      >
        Download PDF
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'
import { formattedDate } from '@/tools/util'
import KycStatus from '@/views/apps/kyc/components/status.vue'
import KycLevel from '@/views/apps/kyc/components/level.vue'
import { kycData } from '@/views/apps/kyc/config/kycTypes'

export default {
  name: 'KycCustomer',
  components: {
    KycStatus,
    KycLevel,
    BButton,
    BRow,
    BCol,
  },
  props: {
    mainData: {
      type: Object,
      default: () => ({}),
    },
    isShowViewButton: {
      type: Boolean,
      default: true,
    },
    isShowDownloadButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formattedDate,
    }
  },
  computed: {
    infoRow() {
      const submittedAt = this.mainData.submittedAt ? this.formattedDate(this.mainData.submittedAt, kycData) : ''

      return [
        {
          label: 'Status:',
          value: this.mainData.status,
          type: 'status',
        },
        {
          label: 'Kyc level:',
          value: this.mainData.level,
          type: 'level',
        },
        {
          label: 'Application ID:',
          value: this.mainData.id,
          type: 'text',
        },
        {
          label: 'Submitted At:',
          value: submittedAt,
          type: 'text',
        },
      ]
    },
    customer() {
      return this.mainData.user
    },
  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.primary-color {
  color: $primary;
}
</style>
