<template>
  <b-card no-body>
    <b-row
      align-v="center"
      class="pl-2 pr-2"
    >
      <b-col
        md="12"
        xl="6"
      >
        <h4
          :class="{'mt-xl-0':isChangeResponsible}"
          class="mb-1 mt-1"
        >
          Identity verification
        </h4>
      </b-col>
      <b-col
        class="d-flex justify-content-xl-end align-items-center"
        md="12"
        xl="6"
      >
        <div
          v-show="isChangeResponsible"
          class="inputs"
        >
          <UserFilter
            ref="responsible"
            :search-action="fetchAdminUsers"
            label="name"
            placeholder="Select responsible"
            required
            @change="onResponsibleIdChange"
          />
        </div>
        <b-button
          v-if="!mainData.responsible"
          class="text-left ml-1"
          type="button"
          variant="light"
          @click="onClickToDo"
        >
          To Do
        </b-button>
        <b-button
          v-else-if="isChangeResponsible"
          :disabled="tempId === responsibleId || requestInProgress"
          class="text-left text-nowrap ml-1"
          variant="light"
          @click="onClickToDo"
        >
          Change
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BButton, BCard, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserFilter from '@/components/containers/UserFilter.vue'
import waitRequest from '@/mixins/waitRequest'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'

export default {
  name: 'KycHeader',
  components: {
    BRow,
    ToastificationContent,
    BCol,
    BButton,
    UserFilter,
    BCard,
  },
  mixins: [waitRequest, checkErrorsAlert],
  props: {
    isChangeResponsible: {
      type: Boolean,
      default: false,
    },
    mainData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      tempId: null,
      responsibleId: null,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const admin = this.$store.getters['auth/admin']
      const target = this.mainData.responsible || admin
      this.tempId = this.mainData.responsible?.id || null

      this.$refs?.responsible?.setManual(target)
      this.responsibleId = target?.id || null
    },
    ...mapActions({
      fetchAdminUsers: 'adminUser/fetchAdminUsers',
      setResponsible: 'kyc/setResponsible',
    }),

    async onClickToDo() {
      const data = {
        id: this.mainData.id,
        responsibleId: this.responsibleId,
      }
      this.waitRequest(async () => {
        await this.setResponsible(data).then(() => {
          this.tempId = data.id
          this.responsibleId = data.id
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Applicant Information is updated',
              icon: 'UserPlusIcon',
              variant: 'info',
            },
          })
          this.$emit('onChange')
        }).catch(this.checkErrorsAlert)
      })
    },

    onResponsibleIdChange(value) {
      this.responsibleId = value.id
    },
  },

}
</script>
<style lang="scss" scoped>
.inputs {
  margin-top: 1rem;
  width: 220px;
}
</style>
